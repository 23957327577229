import { useMemo, useState, useEffect } from "react";
import DataTable from "../../../components/table/DataTable";
import { columns } from "../columns";
import Pagination from "../../../components/Pagination/Pagination";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import SpinnerOverlay from "../../../components/SpinnerOverlay/SpinnerOverlay";
import NoContentMessage from "../../../components/NoContentMessage/NoContentMessage";
import SearchInput from "../../../components/SearchInput/SearchInput";
import { SignalReportModal } from "../../../components";
import useScreenedManuscripts from "../hooks/useScreenedManuscripts";
import {
  convertISOToReadableDate,
  getAmbientScreeningSubmissionById,
} from "../../../helpers";
import { ReportActionButtons } from "../components/ReportActionButtons";

export const UnderReviewManuscriptsTab = ({ isActiveTab }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalContentData, setModalContentData] = useState(null);

  const {
    data,
    isLoading,
    setIsLoading,
    error,
    pageCount,
    currentPage,
    setCurrentPage,
    searchTerm,
    setSearchTerm,
  } = useScreenedManuscripts({ tab: "under_review_manuscripts", isActiveTab });

  const handleSearchFetch = (value) => {
    const trimmedValue = value.trim();
    if (searchTerm || trimmedValue) setSearchTerm(trimmedValue);
  };

  const fetchScreeningData = async (index) => {
    const item = data[index];
    if (
      !item ||
      !item.signals.some((signal) => signal !== "duplicateSubmissions")
    ) {
      setModalContentData(item);
      return;
    }
    if (item.persistent_id) {
      setIsLoading(true);
      try {
        const { data: fetchedData } = await getAmbientScreeningSubmissionById(
          item.persistent_id
        );
        setModalContentData(fetchedData);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (selectedItem) fetchScreeningData(selectedItem.index);
  }, [selectedItem]);

  const resetModalState = () => {
    setModalContentData(null);
    setSelectedItem(null);
  };

  const mappedData = useMemo(
    () =>
      data?.map((manuscript, i) => ({
        ...manuscript,
        timestamp: convertISOToReadableDate(
          manuscript.timestamp,
          "DD MMM YYYY"
        ),
        signals: (
          <ReportActionButtons
            signals={manuscript.signals}
            onIconClick={(buttonKey) =>
              setSelectedItem({ index: i, key: buttonKey })
            }
          />
        ),
      })) || [],
    [data]
  );

  return (
    <SpinnerOverlay active={isLoading} centerOnScreen={true}>
      {selectedItem?.index !== null && modalContentData && !isLoading && (
        <SignalReportModal
          itemKey={selectedItem.key}
          dupSubData={data[selectedItem?.index]}
          contentData={modalContentData}
          onClose={resetModalState}
        />
      )}
      <MDBRow className="mb-2 justify-content-end">
        <MDBCol sm={8} md={6} lg={4}>
          <SearchInput
            placeholder="Search by manuscript ID or title"
            setValue={setSearchTerm}
            onSubmit={handleSearchFetch}
          />
        </MDBCol>
      </MDBRow>
      {error && <ErrorMessage />}
      {!error && !isLoading && !data?.length && <NoContentMessage />}
      {!error && !!data?.length && (
        <>
          <DataTable columns={columns} rows={mappedData} />
          <Pagination
            handlePageClick={(e) => setCurrentPage(e.selected)}
            pageCount={pageCount}
            currentPage={currentPage}
          />
        </>
      )}
    </SpinnerOverlay>
  );
};
