import Jsontableify from "jsontableify";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import React from "react";
import {
  capitalizeFirstLetter,
  convertISOToReadableDate,
} from "../../../helpers";
import Parser from "html-react-parser";
import { ReportActionButtons } from "../../screened-manuscripts/components/ReportActionButtons";
import "./DuplicateSubmissionsDataReview.css";

const DuplicateSubmissionsDataReview = ({ data, withSubHeader }) => {
  const getStatusSummary = (item) => {
    const results = [];
    const { submission_id, submission_status } = item;

    if (submission_status === "duplicate confirmed") {
      results.push("Duplicate Confirmed");
    } else if (submission_status === "suspect paper mill") {
      results.push("Suspect Paper Mill Reported");
    } else if (submission_status === "false positive") {
      results.push("Reported False Positive");
    }
    if (!submission_id) {
      results.push("Submission Removed");
    }

    return capitalizeFirstLetter(results.join("; "));
  };

  const formatReport = (data) => {
    const formatManuscript = (item) => {
      const {
        submitted,
        manuscript_id,
        journal,
        publisher_name,
        title,
        feedback_email,
      } = item;
      const submissionStatus = getStatusSummary(item);

      return {
        ...(submissionStatus && {
          Status: `<span class="highlighted-status">${submissionStatus}</span>`,
        }),
        Date: convertISOToReadableDate(submitted, "DD MMM YYYY HH:mm"),
        Publisher: feedback_email
          ? `<a href="mailto:${feedback_email}?subject=${manuscript_id}">${publisher_name}</a>`
          : publisher_name,
        Journal: journal,
        ManuscriptID: manuscript_id,
        Title: title,
      };
    };
    const { matches, trigger } = data;
    const reportData = formatManuscript(trigger);
    const formattedMatches = matches?.map(formatManuscript);

    return {
      ...reportData,
      "Matched Manuscripts": formattedMatches?.length ? formattedMatches : null,
    };
  };

  const renderModalContent = (data) => {
    if (!data) return null;

    const reportData = formatReport(data);
    const jt = new Jsontableify();
    const content = jt.toHtml(reportData).html;
    return <div className="modal_table_wrapper">{Parser(content)}</div>;
  };

  return (
    <>
      {(!!data?.matches || !!data?.trigger) && (
        <MDBCard className="my-2">
          {withSubHeader && (
            <MDBCardHeader className="d-flex">
              <ReportActionButtons signals={["duplicateSubmissions"]} />
              <MDBCardTitle className="pt-2 ps-2">
                Duplicate Submissions
              </MDBCardTitle>
            </MDBCardHeader>
          )}
          <MDBCardBody>{renderModalContent(data)}</MDBCardBody>
        </MDBCard>
      )}
    </>
  );
};

export default DuplicateSubmissionsDataReview;
