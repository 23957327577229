import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import { renderListItems, renderDoiItem } from "./ReferenceAnalysisReviewUtils";
import { InfoTooltip } from "../../../components";
import { ReportActionButtons } from "../../screened-manuscripts/components/ReportActionButtons";

const ReferenceAnalysisDataReview = ({ data }) => {
  const retractionWatchData =
    data?.screening_outcomes?.find((x) => x.type === "retractionWatch") || {};
  const doiResolutionsData =
    data?.screening_outcomes?.find((x) => x.type === "doiResolutions") || {};

  const retractionWatchItems = retractionWatchData.items ?? [];
  const doiResolutionsItems = doiResolutionsData.items ?? [];

  const renderHeader = (title, itemCount, supplementaryData) => (
    <>
      <span className="text-primary">{title}</span> {itemCount}
      {supplementaryData?.retrievedReferences !== undefined && (
        <span>/{supplementaryData.retrievedReferences}</span>
      )}
      {supplementaryData?.totalReferences !== undefined && (
        <span> ({supplementaryData.totalReferences})</span>
      )}
    </>
  );

  return (
    <>
      {retractionWatchItems.length > 0 && (
        <MDBCard className="my-2">
          <MDBCardHeader className="d-flex">
            <ReportActionButtons
              signals={["retractionWatch", "doiResolutions"]}
            />
            <MDBCardTitle className="pt-2 ps-2">
              {renderHeader(
                "Retraction Watch",
                retractionWatchItems.length,
                retractionWatchData.supplementary_data
              )}
              <InfoTooltip
                tooltipText={
                  <>
                    This signal checks if references are present in the
                    Retraction Watch database.
                    <br />
                    &#x25cf; The first number displays the number of references
                    found in the Retraction Watch database.
                    <br />
                    &#x25cf; The number in brackets is the total number of
                    references found in the manuscript.
                  </>
                }
              />
            </MDBCardTitle>
          </MDBCardHeader>
          <MDBCardBody>
            {renderListItems(retractionWatchItems, renderDoiItem)}
          </MDBCardBody>
        </MDBCard>
      )}

      {doiResolutionsItems.length > 0 && (
        <MDBCard className="mt-3">
          <MDBCardHeader>
            <MDBCardTitle className="pt-2">
              {renderHeader(
                "DOI Analysis",
                doiResolutionsItems.length,
                doiResolutionsData.supplementary_data
              )}
              <InfoTooltip
                tooltipText={
                  <>
                    This signal analyses references in the manuscript.
                    <br />
                    &#x25cf; The first number signifies the number of DOIs
                    listed in the reference list that do not exist (via check
                    with DOI.org).
                    <br />
                    &#x25cf; The second number signifies the total number of
                    DOIs that were listed in the reference list or could be
                    retrieved using our software.
                    <br />
                    &#x25cf; The number in brackets is the total number of
                    references found in the manuscript.
                  </>
                }
              />
            </MDBCardTitle>
          </MDBCardHeader>
          <MDBCardBody>
            {renderListItems(doiResolutionsItems, renderDoiItem)}
          </MDBCardBody>
        </MDBCard>
      )}
    </>
  );
};

export default ReferenceAnalysisDataReview;
