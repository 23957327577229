import React, { useEffect, useState } from "react";
import {
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
} from "mdb-react-ui-kit";
import { watchlistSignals } from "../../constants";
import { ModalWrapper } from "../ModalWrapper";
import DuplicateSubmissionsDataReview from "../../pages/duplicate-submissions/components/DuplicateSubmissionsDataReview";
import ClearskiesDataReview from "../../pages/clearskies/components/ClearskiesDataReview";
import PubpeerDataReview from "../../pages/pubpeer/components/PubpeerDataReview";
import ReferenceAnalysisDataReview from "../../pages/reference-analysis/components/ReferenceAnalysisDataReview";
import MetadataAnalysisDataReview from "../../pages/MetadataAnalysis/components/MetadataAnalysisDataReview";
import TextAnalysisDataReview from "../../pages/text-analysis/components/TextAnalysisDataReview";
import WatchlistDataReview from "../../pages/watchlist/components/WatchlistDataReview";
import { SECTION_KEYS } from "../../pages/screened-manuscripts/components/ReportActionButtons";
import { SignalReportSubmissionCard } from "./SignalReportSubmissionCard";

const tabs = {
  duplicateSubmission: "duplicateSubmission",
  externalTools: "externalTools",
  analysisTools: "analysisTools",
  watchlist: "watchlist",
};

const tabLabels = {
  duplicateSubmission: "Duplicate Submission",
  externalTools: "External Tools",
  analysisTools: "Analysis Tools",
  watchlist: "Watchlist",
};

const tabSignalMappings = {
  [tabs.duplicateSubmission]: ["duplicateSubmissions"],
  [tabs.externalTools]: ["clearSkiesStatus", "feetOfClayDetector"],
  [tabs.analysisTools]: [
    "retractionWatch",
    "doiResolutions",
    "wordFileProperties",
    "torturedPhrases",
  ],
};

export const SignalReportModal = ({
  onClose,
  contentData,
  dupSubData,
  itemKey,
}) => {
  const isOpen = !!contentData || !!dupSubData;
  const [currentTab, setCurrentTab] = useState();

  const tabVisibility = {
    [tabs.duplicateSubmission]: dupSubData?.signals.some((x) =>
      tabSignalMappings[tabs.duplicateSubmission].includes(x)
    ),
    [tabs.externalTools]: contentData?.signals?.some((x) =>
      tabSignalMappings[tabs.externalTools].includes(x)
    ),
    [tabs.analysisTools]: contentData?.signals?.some((x) =>
      tabSignalMappings[tabs.analysisTools].includes(x)
    ),
    [tabs.watchlist]: contentData?.signals?.some((x) =>
      watchlistSignals.some((signal) => signal.value === x)
    ),
  };

  useEffect(() => {
    const tabKeyMap = {
      [SECTION_KEYS.DUPSUB]: tabs.duplicateSubmission,
      [SECTION_KEYS.CLEARSKIES]: tabs.externalTools,
      [SECTION_KEYS.PUBPEER]: tabs.externalTools,
      [SECTION_KEYS.METADATA_ANALYSIS]: tabs.analysisTools,
      [SECTION_KEYS.REFERENCE_ANALYSIS]: tabs.analysisTools,
      [SECTION_KEYS.TEXT_ANALYSIS]: tabs.analysisTools,
      [SECTION_KEYS.WATCHLIST]: tabs.watchlist,
    };
    setCurrentTab(
      tabKeyMap[itemKey] ||
        Object.keys(tabVisibility).find((tab) => tabVisibility[tab])
    );
  }, [itemKey]);

  const renderTabContent = () => {
    switch (currentTab) {
      case tabs.duplicateSubmission:
        return (
          <DuplicateSubmissionsDataReview data={dupSubData} withSubHeader />
        );
      case tabs.externalTools:
        return (
          <>
            <SignalReportSubmissionCard data={contentData} />
            <ClearskiesDataReview data={contentData} withSubHeader />
            <PubpeerDataReview data={contentData} withSubHeader />
          </>
        );
      case tabs.analysisTools:
        return (
          <>
            <SignalReportSubmissionCard data={contentData} />
            <ReferenceAnalysisDataReview data={contentData} />
            <MetadataAnalysisDataReview data={contentData} withSubHeader />
            <TextAnalysisDataReview data={contentData} withSubHeader />
          </>
        );
      case tabs.watchlist:
        return (
          <>
            <SignalReportSubmissionCard data={contentData} />
            <WatchlistDataReview data={contentData} />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <ModalWrapper title={"Signal(s) report"} isOpen={isOpen} onClose={onClose}>
      <MDBTabs className="mb-3">
        {Object.keys(tabVisibility).map(
          (tab) =>
            tabVisibility[tab] && (
              <MDBTabsItem key={tab} onClick={() => setCurrentTab(tab)}>
                <MDBTabsLink active={currentTab === tab}>
                  {tabLabels[tab]}
                </MDBTabsLink>
              </MDBTabsItem>
            )
        )}
      </MDBTabs>
      <MDBTabsContent>{renderTabContent()}</MDBTabsContent>
    </ModalWrapper>
  );
};
