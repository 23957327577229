import {
  MDBCard,
  MDBCardHeader,
  MDBCardTitle,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { renderIndicationDisplay } from "./TextAnalysisDataReviewUtils";
import { InfoTooltip } from "../../../components";
import { ReportActionButtons } from "../../screened-manuscripts/components/ReportActionButtons";

const TextAnalysisDataReview = ({ data, withSubHeader = false }) => {
  const torturedPhrases =
    data?.screening_outcomes?.find((x) => x.type === "torturedPhrases")
      ?.items ?? [];

  return (
    <>
      {!!torturedPhrases.length && (
        <MDBCard className="my-2">
          {withSubHeader && (
            <MDBCardHeader className="d-flex">
              <ReportActionButtons signals={["torturedPhrases"]} />
              <MDBCardTitle className="pt-2 ps-2">
                Tortured phrases
                <InfoTooltip
                  tooltipText={
                    <>
                      The text of the manuscript is checked against thousands of
                      phrases that were identified by publishers and Dr.
                      Guillame Cabanac et al. as likely being AI-generated or
                      rewritten. The database is refreshed regularly.
                    </>
                  }
                />
              </MDBCardTitle>
            </MDBCardHeader>
          )}
          <MDBCardBody>
            {torturedPhrases.map((item, index) =>
              renderIndicationDisplay(item, index)
            )}
          </MDBCardBody>
        </MDBCard>
      )}
    </>
  );
};

export default TextAnalysisDataReview;
